// LazyImage.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import QueueRender, { childStates } from '../component/QueueRender.jsx';

class LazyImage extends React.PureComponent {
  imgRef = React.createRef();
  queueTimer = null;
  state = { isIntersected: false, shouldEnqueue: false };

  handleChange = ({ isIntersected, isIntersecting }) => {
    const { queueConfig } = this.props;
    if (queueConfig) {
      if (isIntersecting) {
        this.queueTimer = setTimeout(() => {
          this.setState({ shouldEnqueue: true });
        }, queueConfig.queueDelayMsec || 0);
      } else {
        clearTimeout(this.queueTimer);
      }
    }

    // TODO: below is just for unit test, should refactor later.
    const { isIntersected: prevIsIntersected } = this.state;
    return this.setState({ isIntersected: prevIsIntersected || isIntersected });
  };

  reduceSource = (acc, child, idx) => {
    if (child.type !== 'source') {
      return acc;
    }

    const newProps = {
      ...child.props,
      key: idx,
    };

    return acc.concat([React.createElement('source', newProps)]);
  };

  componentDidMount() {
    const { fetchPriority } = this.props;
    if (this.imgRef.current) {
      // Could remove after reactjs supports `<img fetchPriority />`
      this.imgRef.current.fetchPriority = fetchPriority;
    }
  }

  componentDidUpdate() {
    const { fetchPriority } = this.props;
    if (
      this.imgRef.current &&
      this.imgRef.current.fetchPriority !== fetchPriority
    ) {
      // Could remove after reactjs supports `<img fetchPriority />`
      this.imgRef.current.fetchPriority = fetchPriority;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.queueTimer);
  }

  renderWithoutQueue = () => {
    const { isIntersected: stateIsIntersected } = this.state;
    const { threshold, rootMargin, children, ...restProps } = this.props;
    const childArray = React.Children.toArray(children);

    return (
      <WithIntersectionObserver
        threshold={threshold}
        rootMargin={rootMargin}
        onChange={this.handleChange}
      >
        {({ isIntersected }) => {
          return stateIsIntersected || isIntersected ? (
            <Wrapper>
              <Picture>
                {childArray.reduce(this.reduceSource, [])}
                <Image {...restProps} draggable="false" ref={this.imgRef} />
              </Picture>
            </Wrapper>
          ) : (
            <Wrapper />
          );
        }}
      </WithIntersectionObserver>
    );
  };

  renderWithQueue = () => {
    const { shouldEnqueue } = this.state;
    const { queueConfig, threshold, rootMargin, children, ...restProps } =
      this.props;
    const childArray = React.Children.toArray(children);

    return (
      <WithIntersectionObserver
        threshold={threshold}
        rootMargin={rootMargin}
        onChange={this.handleChange}
      >
        {() => {
          return shouldEnqueue ? (
            <Wrapper>
              <QueueRender
                {...queueConfig}
                render={({ resolve, reject, childState }) => {
                  if (childStates.WAITING === childState) {
                    return null;
                  }
                  return (
                    <Picture onLoad={resolve} onError={reject}>
                      {childArray.reduce(this.reduceSource, [])}
                      <Image
                        {...restProps}
                        draggable="false"
                        ref={this.imgRef}
                      />
                    </Picture>
                  );
                }}
              />
            </Wrapper>
          ) : (
            <Wrapper />
          );
        }}
      </WithIntersectionObserver>
    );
  };

  render() {
    const { queueConfig } = this.props;
    return queueConfig ? this.renderWithQueue() : this.renderWithoutQueue();
  }
}

LazyImage.propTypes = {
  children: PropTypes.node,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  fetchPriority: PropTypes.string,
  queueConfig: PropTypes.object,
  rootMargin: PropTypes.string,
};

LazyImage.defaultProps = {
  children: null,
  threshold: 1.0,
  fetchPriority: 'low',
  queueConfig: null,
  rootMargin: '0px',
};

const Picture = styled.picture`
  display: block;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

Image.displayName = 'Image'; // for LazyImage.test.js component finding

const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

export default LazyImage;
